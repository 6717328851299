import React, { memo, useMemo } from 'react';
import { useTranslation } from '@/middleware/i18n';
import { I18N_CODES } from '@/lib/constants/general';
import { IconAdd } from '@/components/ui/icons/IconAdd';
import { IconExternalLink } from '@/components/ui/icons/IconExternalLink';
import { Button } from '@/components/ui/Button';

interface Props {
  onClick?: () => void;
  isLoading: boolean;
  hasMoreReviews: boolean;
}

export const ShowMoreReviewsButton = memo<Props>((props) => {
  const {
    onClick,
    isLoading,
    hasMoreReviews,
  } = props;

  const { t } = useTranslation([I18N_CODES.reviews]);

  const buttonProps = useMemo(
    () => (hasMoreReviews
      ? {
        text: t(`${I18N_CODES.reviews}:reviews_show_more_button`),
        LeftIcon: IconAdd,
      }
      : {
        href: t(`${I18N_CODES.common}:dou_reviews_link`),
        text: t(`${I18N_CODES.reviews}:reviews_show_all_button`),
        target: '_blank',
        rel: 'nofollow noreferrer noopener',
        RightIcon: IconExternalLink,
      }),
    [hasMoreReviews, t],
  );

  return (
    <Button
      size={Button.size.Large}
      mode={Button.mode.BrandSecondary}
      data-qa="show-more-reviews-button"
      isLoading={isLoading}
      onClick={onClick}
      {...buttonProps}
    />
  );
});
