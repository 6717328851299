import React, {
  FC,
} from 'react';
import { I18N_CODES } from '@/lib/constants/general';
import { useTranslation } from '@/middleware/i18n';
import { cn } from '@/lib';
import { typography } from '@/components/ui/typography';
import { VideoReviewsList } from '@/components/reviews-landing/VideoReviewsList/VideoReviewsList';
import { VideoReview } from '@/controllers/reviews/reviews.typedefs';
import { SHOW_VIDEO_REVIEWS_NUMBER } from '@/controllers/reviews/reviews.constants';
import { useShowMoreReviews } from '@/controllers/reviews/reviews.hooks/useShowMoreReviews';
import { ShowMoreReviewsButton } from '@/components/reviews-landing/ShowMoreReviewsButton';

type Props = {
  reviews: VideoReview[];
  shouldShowShowAllReviewsButton?: boolean;
};

export const VideoReviewsSection: FC<Props> = (props) => {
  const {
    reviews,
    shouldShowShowAllReviewsButton = true,
  } = props;

  const [
    reviewsToShow,
    {
      showMore,
      loading,
    },
  ] = useShowMoreReviews(reviews, SHOW_VIDEO_REVIEWS_NUMBER);

  const hasMoreReviews = reviewsToShow.length < reviews.length;

  const { t } = useTranslation([I18N_CODES.reviews]);

  const shouldShowButton = (
    hasMoreReviews || shouldShowShowAllReviewsButton
  );

  return (
    <>
      <div className="grid-x grid-margin-x align-center mb-80 small-mb-40">
        <div className="large-4">
          <h2
            data-qa="video-reviews-section-title"
            className={cn(
              'text-center',
              typography.landingH2,
            )}
          >
            {t(`${I18N_CODES.reviews}:video_reviews_section_title`)}
          </h2>
        </div>
      </div>

      <div className="grid-x align-center mb-80 small-mb-40">
        <div className="xlarge-12 large-8 medium-12">
          <VideoReviewsList reviews={reviewsToShow} />
        </div>
      </div>

      {shouldShowButton && (
        <div className="grid-x align-center">
          <ShowMoreReviewsButton
            isLoading={loading}
            hasMoreReviews={hasMoreReviews}
            onClick={hasMoreReviews
              ? showMore
              : undefined}
          />
        </div>
      )}
    </>
  );
};
