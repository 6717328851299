import { useCallback, useMemo, useState } from 'react';
import { VideoReview, TextReview } from '@/controllers/reviews/reviews.typedefs';

interface UseShowMoreReviewsHook {
  <T extends VideoReview | TextReview>(
    reviews: T[],
    showMoreReviewsNumber: number,
  ): [
    T[],
    {
      loading: boolean;
      showMore: () => Promise<void>;
    },
  ];
}

export const useShowMoreReviews: UseShowMoreReviewsHook = (
  reviews,
  showMoreReviewsNumber,
) => {
  const [
    loading,
    setLoading,
  ] = useState(false);

  const [
    reviewsNumber,
    setReviewsNumber,
  ] = useState(
    showMoreReviewsNumber,
  );

  const showMoreHandler = useCallback(async () => {
    await new Promise((resolve) => {
      const timeout = 150 * (Math.random() + 0.5);

      setLoading(true);

      setTimeout(() => {
        setLoading(false);

        resolve(0);
      }, timeout);
    });

    setReviewsNumber(reviewsNumber + showMoreReviewsNumber);
  }, [reviewsNumber, showMoreReviewsNumber]);

  return useMemo(
    () => [
      reviews.slice(0, reviewsNumber),
      {
        loading,
        showMore: showMoreHandler,
      },
    ],
    [loading, reviews, reviewsNumber, showMoreHandler],
  );
};
