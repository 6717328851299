import { useMemo } from 'react';
import { useAllCompaniesQuery } from '@/controllers/companies/graphql/generated/allCompanies.query.generated';

interface Options {
  limit?: number;
}

export const useAllCompanies = (
  options?: Options,
) => {
  const { limit = 24 } = options || {};

  const { data } = useAllCompaniesQuery({
    variables: {
      limit,
    },
  });

  return useMemo(() => data?.allCompanies || [], [data]);
};
