import React from 'react';
import { BaseIcon } from '@/components/ui/icons/BaseIcon';
import { FCIcon } from '@/components/ui/icons/typedefs';

export const IconLinkedInColor: FCIcon = (props) => (
  <BaseIcon
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_1562_636)">
      <path
        d="M27.2653 27.2658H22.5239V19.8405C22.5239 18.0698 22.4923 15.7905 20.0579 15.7905C17.5884 15.7905 17.2106 17.7197 17.2106 19.7116V27.2653H12.4693V11.9958H17.0209V14.0826H17.0847C17.5402 13.3037 18.1984 12.663 18.9893 12.2286C19.7802 11.7942 20.674 11.5825 21.5757 11.616C26.3813 11.616 27.2673 14.777 27.2673 18.8892L27.2653 27.2658ZM7.11932 9.90871C5.59969 9.90896 4.36757 8.67721 4.36732 7.15758C4.36707 5.63796 5.59869 4.40583 7.11832 4.40558C8.63794 4.40521 9.87007 5.63696 9.87032 7.15658C9.87045 7.88634 9.58069 8.58626 9.06478 9.10238C8.54887 9.6185 7.84907 9.90854 7.11932 9.90871ZM9.49007 27.266H4.74369V11.9958H9.48994L9.49007 27.266ZM29.6291 0.00220692H2.36119C1.07244 -0.0122931 0.0155669 1.02008 -0.000183105 2.30883V29.6905C0.0150669 30.9798 1.07182 32.0132 2.36107 31.9997H29.6291C30.9211 32.0157 31.9819 30.9823 31.9998 29.6905V2.30671C31.9814 1.01546 30.9204 -0.0167931 29.6291 8.19163e-05"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_1562_636">
        <rect width="32" height="32" fill="white" />
      </clipPath>
    </defs>
  </BaseIcon>
);
