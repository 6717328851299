import React, { FC, useCallback } from 'react';
import dynamic from 'next/dynamic';
import { VimeoProps } from '@u-wave/react-vimeo';
import { I18N_CODES } from '@/lib/constants/general';
import { useTranslation } from '@/middleware/i18n';
import { cn } from '@/lib';
import { typography } from '@/components/ui/typography';
import { Button } from '@/components/ui/Button';
import { IconLinkedInColor } from '@/components/ui/icons/IconLinkedInColor';
import { analyticsSDK } from '@/controllers/analytics';
import { ReviewButtonClickedSource, ReviewButtonType } from '@/controllers/analytics/generated';
import { LandingTag } from '@/components/ui/landing/LandingTag';
import styles from './StudentVideoReview.module.scss';

const VimeoVideoPlayer = dynamic<VimeoProps>(
  import('@u-wave/react-vimeo')
    .then((mod) => mod.default),
);

type Props = {
  courseLabel?: string;
  courseBGColor?: string;
  courseColor?: string;
  name: string;
  jobTitle?: string;
  content: string;
  linkedinUrl: string;
  videoUrl: string;
};

export const StudentVideoReview: FC<Props> = (props) => {
  const {
    courseLabel,
    courseBGColor,
    courseColor,
    name,
    jobTitle,
    content,
    linkedinUrl,
    videoUrl,
  } = props;

  const handleClick = useCallback(() => {
    analyticsSDK.landing.sendReviewButtonClickedEvent({
      type: ReviewButtonType.LinkedIn,
      source: ReviewButtonClickedSource.ReviewsPage,
      courseLabel,
    });
  }, [courseLabel]);

  const { t } = useTranslation([I18N_CODES.reviews]);

  const shouldUseCustomTagColor = (
    courseBGColor && courseColor
  );

  return (
    <div className={cn(styles.item)} data-qa="student-video-review">
      <div className={styles.video}>
        <div className='video-player-container' data-qa="video-container">
          <VimeoVideoPlayer
            video={videoUrl}
            className="video-player"
            showByline={false}
            speed
            pip={false}
            showTitle={false}
            showPortrait={false}
            responsive
          />
        </div>
      </div>
      <div className={styles.info} data-qa="student-video-info">
        {courseLabel && (
          <LandingTag
            className={styles.courseLabel}
            customColor={shouldUseCustomTagColor
              ? (
                {
                  backgroundColor: courseBGColor,
                  color: courseColor,
                  borderColor: `rgba(${courseColor}, 0.1)`,
                }
              )
              : undefined}
          >
            {courseLabel}
          </LandingTag>
        )}

        <div className='mb-24'>
          <div className={cn(
            typography.landingH4,
            'mb-8',
          )}
          >
            {name}
          </div>

          {jobTitle && (
            <div className={cn(
              styles.jobTitle,
              typography.landingTextMain,
            )}
            >
              {jobTitle}
            </div>
          )}
        </div>

        <p className={cn(
          styles.reviewContent,
          typography.landingTextMain,
          'mb-24 small-mb-0',
        )}
        >
          {content}
        </p>

        <div className={styles.filler} />

        <Button
          mode={Button.mode.Secondary}
          size={Button.size.Large}
          href={linkedinUrl}
          onClick={handleClick}
          data-qa="linkedin-button"
          hasFullWidth
          target='_blank'
          className={styles.linkedInButton}
          LeftIcon={IconLinkedInColor}
          text={t(`${I18N_CODES.reviews}:student_linekedin_button`)}
        />
      </div>
    </div>
  );
};
