import React, { FunctionComponent } from 'react';
import { UltraHover } from '@/components/landing/UltraHover';
import { Image } from '@/components/ui/Image';
import styles from './LogoItem.module.scss';

interface LogoItemInterface {
  src: string;
  title: string;
  width?: number;
  height?: number;
}
const LogoItemTemplate: FunctionComponent<LogoItemInterface> = (props) => {
  const {
    src,
    title,
    width = 120,
    height = 120,
  } = props;

  return (
    <div className={styles.wrapper}>
      <UltraHover className={styles.item}>
        <Image
          src={src}
          width={width}
          height={height}
          objectFit="contain"
          alt={title}
          className={styles.image}
        />
      </UltraHover>
    </div>
  );
};

export const LogoItem = React.memo(LogoItemTemplate);
