import React, { FC } from 'react';
import { cn } from '@/lib';
import {
  LandingTagColor,
} from '@/components/ui/landing/LandingTag/LandingTag.typedefs';
import {
  landingTagColorClassNames,
} from '@/components/ui/landing/LandingTag/landingTagColorClassNames';
import styles from './LandingTag.module.scss';

interface Props {
  color?: LandingTagColor;
  className?: string;
  customColor?: {
    backgroundColor: string;
    color: string;
    borderColor: string;
  };
}

export const LandingTag: FC<Props> = ({
  color = LandingTagColor.Gray,
  className,
  customColor,
  children,
}) => (
  <span
    className={cn(styles.tag, landingTagColorClassNames[color], className)}
    style={customColor}
  >
    {children}
  </span>
);
