import styles from '@/components/ui/landing/LandingTag/LandingTag.module.scss';
import {
  LandingTagColor,
} from '@/components/ui/landing/LandingTag/LandingTag.typedefs';

export const landingTagColorClassNames: Record<LandingTagColor, string> = {
  [LandingTagColor.Red]: styles.red,
  [LandingTagColor.Orange]: styles.orange,
  [LandingTagColor.Yellow]: styles.yellow,
  [LandingTagColor.LightGreen]: styles.lightGreen,
  [LandingTagColor.Green]: styles.green,
  [LandingTagColor.Blue]: styles.blue,
  [LandingTagColor.Navy]: styles.navy,
  [LandingTagColor.Purple]: styles.purple,
  [LandingTagColor.Pink]: styles.pink,
  [LandingTagColor.Gray]: styles.gray,
  [LandingTagColor.DarkGray]: styles.darkGray,
};
