import { CourseReviewsBaseFragment } from '@/controllers/reviews/graphql/generated/courseReviewsBase.generated';
import { VideoReview, TextReview } from '@/controllers/reviews/reviews.typedefs';

export const mapFnCourseReviewToTextReview = (
  courseReview: CourseReviewsBaseFragment,
): TextReview | null => {
  const {
    id,
    review,
  } = courseReview;

  const authorUsername = review?.authorUsername.trim();

  if (!review
    || !authorUsername
    || !review.comment) {
    return null;
  }

  return {
    id,
    content: review.comment,
    jobTitle: review.authorJobTitle ?? undefined,
    name: authorUsername,
    sourceUrl: review.sourceLink ?? undefined,
    avatar: review.photo ?? review.authorAvatar ?? undefined,
    createdAt: review.createdAt,
  };
};

export const mapFnCourseReviewToVideoReview = (
  courseReview: CourseReviewsBaseFragment,
): VideoReview | null => {
  const {
    course,
    review,
    id,
  } = courseReview;

  if (!course
    || !review
    || !review.authorLinkedin
    || !review.videoLink
  ) {
    return null;
  }

  return {
    id,
    content: review.comment,
    courseBGColor: course?.bgColor ?? undefined,
    courseColor: course?.color ?? undefined,
    courseLabel: course.profession?.name
      || course.nameShort,
    linkedinUrl: review.authorLinkedin,
    name: review.authorUsername,
    jobTitle: review.authorJobTitle ?? undefined,
    videoUrl: review.videoLink,
    createdAt: review.createdAt,
  };
};
