import React, { FC } from 'react';
import { cn } from '@/lib/classNames';
import { LogoItemsListBlock } from '@/components/landing/LogoItemsListBlock';
import { section } from '@/components/ui/section';
import { CompanyFragment } from '@/controllers/companies/graphql/generated/Company.fragment.generated';

interface Props {
  id?: string;
  title: string;
  moreItemsText: string;
  companies: CompanyFragment[];
}

export const CompaniesBlock: FC<Props> = (props) => {
  const {
    id,
    companies,
    title,
    moreItemsText,
  } = props;

  return (
    <div
      id={id}
      className={cn({ [section.scrollSection]: Boolean(id) })}
    >
      <LogoItemsListBlock
        title={title}
        moreItemsText={moreItemsText}
        items={companies}
      />
    </div>
  );
};
