import { useMemo } from 'react';
import { useCourseReviewsQuery } from '@/controllers/reviews/graphql/generated/courseReviews.query.generated';
import { useSubDomainContext } from '@/controllers/subDomain/subDomain.hooks/useSubDomainContext';
import { TextReview, VideoReview } from '@/controllers/reviews/reviews.typedefs';
import { truthy } from '@/lib/helpers/functional';
import { mapFnCourseReviewToTextReview, mapFnCourseReviewToVideoReview } from '@/controllers/reviews/reviews.helpers';
import { isVimeoVideoLink } from '@/controllers/forms/forms.validator/validationRules';
import { ReviewType } from '@/controllers/graphql/generated';
import { LOAD_TEXT_REVIEWS_NUMBER, LOAD_VIDEO_REVIEWS_NUMBER } from '@/controllers/reviews/reviews.constants';

interface TextReviewsOptions {
  courseId?: number;
  isBasedOnDomain?: boolean;
}

interface VideoReviewsOptions {
  isVimeoOnly?: boolean;
  isBasedOnDomain?: boolean;
  skip?: boolean;
}

interface UseCourseReviewsOptions {
  textReviewsOptions?: TextReviewsOptions;
  videoReviewsOptions?: VideoReviewsOptions;
}

export interface UseCourseReviewsFn {
  (options?: UseCourseReviewsOptions): {
    textReviews: TextReview[];
    videoReviews: VideoReview[];
  };
}

export const useCourseTextReviews = (
  options?: TextReviewsOptions,
): TextReview[] => {
  const {
    courseId,
    isBasedOnDomain,
  } = options || {};

  const {
    language,
    subDomain,
  } = useSubDomainContext();

  const { data } = useCourseReviewsQuery({
    variables: {
      language,
      limit: LOAD_TEXT_REVIEWS_NUMBER,
      type: ReviewType.Text,
      ...(isBasedOnDomain && { domain: subDomain }),
      ...(courseId && { courseId }),
    },
  });

  return useMemo(
    () => (data?.courseReviews || [])
      .map(mapFnCourseReviewToTextReview)
      .filter(truthy),
    [data?.courseReviews],
  );
};

export const useCourseVideoReviews = (
  options?: VideoReviewsOptions,
): VideoReview[] => {
  const {
    isVimeoOnly = true,
    isBasedOnDomain,
    skip,
  } = options || {};

  const {
    language,
    subDomain,
  } = useSubDomainContext();

  const { data } = useCourseReviewsQuery({
    variables: {
      language,
      limit: LOAD_VIDEO_REVIEWS_NUMBER,
      type: ReviewType.Video,
      ...(isBasedOnDomain && { domain: subDomain }),
    },
    skip,
  });

  return useMemo(
    () => (data?.courseReviews || [])
      .map(mapFnCourseReviewToVideoReview)
      .filter(truthy)
      .filter((review) => (isVimeoOnly
        ? isVimeoVideoLink(review.videoUrl)
        : true)),
    [data?.courseReviews, isVimeoOnly],
  );
};

export const useCourseReviews: UseCourseReviewsFn = (options) => {
  const {
    textReviewsOptions,
    videoReviewsOptions,
  } = options || {};

  const textReviews = useCourseTextReviews(textReviewsOptions);
  const videoReviews = useCourseVideoReviews(videoReviewsOptions);

  return useMemo(() => ({
    textReviews,
    videoReviews,
  }), [textReviews, videoReviews]);
};
