import React, { FC } from 'react';
import { cn } from '@/lib';
import { StudentVideoReview } from '@/components/reviews-landing/StudentVideoReview/StudentVideoReview';
import { VideoReview } from '@/controllers/reviews/reviews.typedefs';
import styles from './VideoReviewsList.module.scss';

type Props = {
  reviews: VideoReview[];
};

export const VideoReviewsList: FC<Props> = (props) => {
  const { reviews } = props;

  return (
    <div className={cn(styles.container)}>
      {reviews.map((review) => (
        <StudentVideoReview
          key={review.id}
          videoUrl={review.videoUrl}
          courseLabel={review.courseLabel}
          courseBGColor={review.courseBGColor}
          courseColor={review.courseColor}
          name={review.name}
          jobTitle={review.jobTitle}
          content={review.content}
          linkedinUrl={review.linkedinUrl}
        />
      ))}
    </div>
  );
};
